import Head from 'next/head';
import { getFullContentUrl } from '@gn/core/utils/url';

export const ContentListSchema = ({ contents }) => {
    const itemListElements = contents.map((content, index) => {
        return {
            '@type': 'ListItem',
            position: (index + 1).toString(),
            url: getFullContentUrl(content),
        };
    });

    const schema = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: itemListElements,
    };

    return (
        <Head>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}></script>
        </Head>
    );
};
