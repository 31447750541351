import { EventWithBrand } from '../type';

interface Address {
    streetAddress: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    addressCountry: string;
}

interface Geo {
    latitude: number;
    longitude: number;
}

interface ConferenceLocation {
    brands: number[];
    address: Address;
    geo: Geo;
}

export const conferenceLocations: ConferenceLocation[] = [
    {
        brands: [
            2, // React Summit
            8, // JSNation
            23, // C3 Dev Festival
        ],
        address: {
            streetAddress: 'Gedempt Hamerkanaal 231',
            addressLocality: 'Amsterdam',
            addressRegion: 'North Holland',
            postalCode: '1021KP',
            addressCountry: 'Netherlands',
        },
        geo: {
            latitude: 52.388569,
            longitude: 4.929309,
        },
    },
    {
        brands: [
            10, // React Advanced
        ],
        address: {
            streetAddress: '52 Chiswell Street',
            addressLocality: 'London',
            addressRegion: 'London',
            postalCode: 'EC1Y 4SD',
            addressCountry: 'UK',
        },
        geo: {
            latitude: 51.520317,
            longitude: -0.093768,
        },
    },
    {
        brands: [
            19, // React Day Berlin
        ],
        address: {
            streetAddress: 'Karl-Marx-Allee 131A',
            addressLocality: 'Berlin',
            addressRegion: 'Berlin',
            postalCode: '10243',
            addressCountry: 'Germany',
        },
        geo: {
            latitude: 52.522523,
            longitude: 13.438189,
        },
    },
    {
        brands: [
            22, // React Summit US
            24, // JSNation US
        ],
        address: {
            streetAddress: '222 Jersey City Blvd',
            addressLocality: 'Jersey City',
            addressRegion: 'Jersey City',
            postalCode: 'NJ 07305',
            addressCountry: 'USA',
        },
        geo: {
            latitude: 40.707707,
            longitude: -74.041048,
        },
    },
];

export const getConferenceLocation = (event: EventWithBrand): ConferenceLocation | undefined =>
    conferenceLocations.find((location) => location.brands.includes(event.brand.id));
