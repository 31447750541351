export default function Linkedin() {
    return (
        <svg
            className="icon icon-in"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4444 14H1.55556C0.696446 14 0 13.3036 0 12.4444V1.55556C0 0.696446 0.696446 0 1.55556 0H12.4444C13.3036 0 14 0.696446 14 1.55556V12.4444C14 13.3036 13.3036 14 12.4444 14ZM9.97734 12.0545H12.0549V7.78665C12.0549 5.9809 11.0312 5.10779 9.60147 5.10779C8.17104 5.10779 7.56904 6.22171 7.56904 6.22171V5.31373H5.5669V12.0545H7.56904V8.51599C7.56904 7.56788 8.00548 7.00368 8.84083 7.00368C9.60871 7.00368 9.97734 7.54584 9.97734 8.51599V12.0545ZM1.94375 3.18722C1.94375 3.87413 2.49637 4.43108 3.17835 4.43108C3.86032 4.43108 4.41261 3.87413 4.41261 3.18722C4.41261 2.50032 3.86032 1.94336 3.17835 1.94336C2.49637 1.94336 1.94375 2.50032 1.94375 3.18722ZM4.23224 12.0545H2.14452V5.31373H4.23224V12.0545Z"
            />
        </svg>
    );
}
