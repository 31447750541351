import { format, isSameDay, isSameMonth, isSameYear } from 'date-fns';
import levenshtein from 'fast-levenshtein';
import fetch from 'node-fetch';
import { getConferenceLocation } from '../config/conferenceLocations';
import { FIND_IP_TOKEN } from '../constants';

const DAY = 'd';
const MONTH = 'MMMM';
const MONTH_SHORT = 'MMM';
const YEAR = 'yyyy';
const MONTH_YEAR = `${MONTH}, ${YEAR}`;
const MONTH_DAY = `${MONTH} ${DAY}`;
const MONTH_DAY_SHORT = `${MONTH_SHORT} ${DAY}`;
const FULL = `${MONTH_DAY}, ${YEAR}`;
const FULL_SHORT = `${MONTH_DAY_SHORT}, ${YEAR}`;

export function formatEventDate({
    startDate,
    endDate,
    noExactDate,
    short,
}: {
    startDate: Date;
    endDate: Date;
    noExactDate: boolean;
    short?: boolean;
}): string {
    if (!(startDate && endDate)) {
        throw new Error('startDate and endDate must be specified');
    }

    if (noExactDate) {
        return format(startDate, MONTH_YEAR);
    }

    switch (true) {
        case !isSameYear(startDate, endDate):
            // Dec 30, 2022 - Jan 1, 2023
            return `${format(startDate, FULL_SHORT)} - ${format(endDate, FULL_SHORT)}`;
        case !isSameMonth(startDate, endDate):
            // Jun 30 - Jul 2, 2022
            return `${format(startDate, MONTH_DAY_SHORT)} - ${format(endDate, MONTH_DAY_SHORT)}, ${format(
                startDate,
                YEAR,
            )}`;
        case !isSameDay(startDate, endDate):
            // June 4 - 5, 2022
            return `${format(startDate, short ? MONTH_DAY_SHORT : MONTH_DAY)} - ${format(endDate, DAY)}, ${format(
                startDate,
                YEAR,
            )}`;
        default:
            // June 4, 2022
            return format(startDate, short ? FULL_SHORT : FULL);
    }
}

const haversineDistance = (coords1, coords2) => {
    const toRad = (x) => (x * Math.PI) / 180;

    const lat1 = coords1.latitude;
    const lon1 = coords1.longitude;
    const lat2 = coords2.latitude;
    const lon2 = coords2.longitude;

    const R = 6371;
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
};

const weight = (arr: Array<{ probability: number; id: number }>): number[] => {
    return arr.map((el) => Array(Math.ceil(el.probability * 100)).fill(el.id)).flat();
};

const selectRelevantEvent = <T extends { id: number; probability: number }>(events: Array<T>) => {
    const weighted = weight(events);
    const selectedId = weighted[Math.floor(Math.random() * weighted.length)];
    return events.find((e) => e.id === selectedId) || events[events.length - 1];
};

export const selectPromotedEvent = ({
    events,
    brandName,
    clientGeo,
}: {
    events: Array<any>;
    brandName: string;
    clientGeo: any;
}) => {
    if (events.length === 0) {
        return null;
    }

    const eventsWithDistances = events.map((event) => {
        const conferenceLocation = getConferenceLocation(event);
        const latitude = conferenceLocation?.geo.latitude;
        const longitude = conferenceLocation?.geo.longitude;

        const eventGeo = { latitude, longitude };

        return {
            ...event,
            levenshteinDistance: levenshtein.get(brandName, event.name),
            geoDistance:
                !!clientGeo && (latitude || longitude) ? haversineDistance(clientGeo, eventGeo) : Number.MAX_VALUE,
        };
    });

    const inverseGeoDistanceSum = eventsWithDistances.reduce((sum, event) => sum + 1 / event.geoDistance, 0);
    const inverseLevenshteinDistanceSum = eventsWithDistances.reduce(
        (sum, event) => sum + 1 / event.levenshteinDistance,
        0,
    );

    const calculateProbability = (event) => {
        const geoProbability = 1 / event.geoDistance / inverseGeoDistanceSum;
        const levenshteinProbability = 1 / event.levenshteinDistance / inverseLevenshteinDistanceSum;

        return geoProbability * levenshteinProbability;
    };

    const eventProbabilities = eventsWithDistances.map((event) => ({
        ...event,
        probability: calculateProbability(event),
    }));

    const totalProbability = eventProbabilities.reduce((sum, event) => sum + event.probability, 0);

    const normalizedEventProbabilities = eventProbabilities
        .map((event) => ({
            ...event,
            probability: event.probability / totalProbability,
        }))
        .sort((a, b) => b.probability - a.probability);

    return selectRelevantEvent(normalizedEventProbabilities);
};

export const getUserGeoByIp = async (clientIp: string | null | undefined) => {
    const clientGeo = {
        latitude: null,
        longitude: null,
        countryCode: null,
    };
    if (clientIp) {
        const response = await fetch(`https://api.findip.net/${clientIp}/?token=${FIND_IP_TOKEN}`);
        const data = await response.json();

        clientGeo.latitude = data.location.latitude;
        clientGeo.longitude = data.location.longitude;
        clientGeo.countryCode = data.country.iso_code;
    }

    return clientGeo;
};
