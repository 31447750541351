export default function InstagramIcon() {
    return (
        <svg
            className="icon icon-instagram"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.99825 4.88843C5.71288 4.88843 4.66392 5.93878 4.66392 7.22585C4.66392 8.51293 5.71288 9.56328 6.99825 9.56328C8.28362 9.56328 9.33257 8.51293 9.33257 7.22585C9.33257 5.93878 8.28362 4.88843 6.99825 4.88843ZM13.9995 7.22585C13.9995 6.25792 14.0082 5.29875 13.9539 4.33256C13.8997 3.21032 13.644 2.21432 12.8244 1.39368C12.0031 0.571283 11.0102 0.317024 9.88945 0.262666C8.9228 0.208307 7.9649 0.217074 7 0.217074C6.03335 0.217074 5.07545 0.208307 4.11055 0.262666C2.98979 0.317024 1.99512 0.573037 1.17556 1.39368C0.35426 2.21608 0.100338 3.21032 0.0460516 4.33256C-0.00823504 5.3005 0.000520868 6.25967 0.000520883 7.22585C0.000520898 8.19204 -0.00823504 9.15296 0.0460516 10.1191C0.100338 11.2414 0.356011 12.2374 1.17556 13.058C1.99687 13.8804 2.98979 14.1347 4.11055 14.189C5.0772 14.2434 6.0351 14.2346 7 14.2346C7.96665 14.2346 8.92455 14.2434 9.88945 14.189C11.0102 14.1347 12.0049 13.8787 12.8244 13.058C13.6457 12.2356 13.8997 11.2414 13.9539 10.1191C14.01 9.15296 13.9995 8.19379 13.9995 7.22585ZM6.99825 10.8223C5.01066 10.8223 3.40657 9.21609 3.40657 7.22585C3.40657 5.23562 5.01066 3.62941 6.99825 3.62941C8.98584 3.62941 10.5899 5.23562 10.5899 7.22585C10.5899 9.21609 8.98584 10.8223 6.99825 10.8223ZM10.737 4.32204C10.273 4.32204 9.89821 3.94679 9.89821 3.48211C9.89821 3.01743 10.273 2.64218 10.737 2.64218C11.2011 2.64218 11.5758 3.01743 11.5758 3.48211C11.576 3.59245 11.5544 3.70173 11.5123 3.8037C11.4702 3.90567 11.4084 3.99832 11.3305 4.07634C11.2525 4.15436 11.16 4.21623 11.0582 4.25839C10.9564 4.30055 10.8472 4.32218 10.737 4.32204Z" />
        </svg>
    );
}
