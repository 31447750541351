export default function TikTokIcon() {
    return (
        <svg
            className="icon icon-tiktok"
            width="13"
            height="15"
            viewBox="0 0 13 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M11.4986 3.50068C10.7996 3.35097 10.1666 2.98161 9.69199 2.44645C9.21735 1.91129 8.92582 1.23831 8.85991 0.525622V0.216553H6.44873V9.79842C6.44724 10.2226 6.31304 10.6357 6.06501 10.9796C5.81698 11.3235 5.46757 11.5809 5.06588 11.7157C4.6642 11.8505 4.23043 11.8559 3.82554 11.7311C3.42064 11.6063 3.06497 11.3576 2.80851 11.02C2.54806 10.6767 2.40358 10.2593 2.39603 9.82822C2.38847 9.39717 2.51823 8.97492 2.76649 8.62269C3.01475 8.27047 3.3686 8.00658 3.77672 7.8693C4.18485 7.73202 4.62604 7.72848 5.03631 7.8592V5.40626C4.12143 5.27962 3.1899 5.44249 2.37201 5.87209C1.55412 6.30168 0.890787 6.97652 0.474789 7.80221C0.058792 8.6279 -0.089062 9.56314 0.0519088 10.4771C0.19288 11.3911 0.615624 12.2381 1.26101 12.8996C1.87648 13.5302 2.66565 13.9628 3.52777 14.1423C4.3899 14.3218 5.28587 14.2399 6.10133 13.9072C6.91678 13.5745 7.61473 13.006 8.10607 12.2743C8.59741 11.5426 8.85986 10.6808 8.85991 9.79912V4.90306C9.83435 5.60063 11.0027 5.97467 12.2006 5.97254V3.57357C11.9647 3.57386 11.7294 3.54943 11.4986 3.50068Z" />
        </svg>
    );
}
