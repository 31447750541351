import React from 'react';

export const EditButton = ({ url }) => {
    return (
        <a
            target="_blank"
            rel="nofollow"
            href={url}
            style={{
                position: 'absolute',
                left: '0',
                top: '0',
                backgroundColor: 'black',
                color: 'white',
                display: 'inline-block',
                padding: '10px',
                opacity: '0.5',
                zIndex: 4,
            }}
        >
            Edit
        </a>
    );
};
