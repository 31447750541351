import classNames from 'classnames';

interface FireIconProps {
    className?: string;
}

export default function FireIcon({ className }: FireIconProps) {
    return (
        <svg
            className={classNames('icon', 'icon-fire', className)}
            width="8"
            height="9"
            viewBox="0 0 8 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1.20432 7.80705C2.73196 9.35893 5.20876 9.35893 6.73639 7.80704C7.50021 7.0311 7.88212 6.01411 7.88212 4.99711C7.88213 3.98011 7.5283 3.32044 6.76448 2.54449C5.9989 1.76676 4.21484 1.76835 3.96346 0.0297852C2.50347 0.774884 2.01448 3.50691 2.99242 4.99711C1.03654 4.74874 1.20432 2.18716 1.20432 2.18716C0.440502 2.96311 0.0585938 3.98011 0.0585938 4.99711C0.0585938 6.01411 0.440505 7.0311 1.20432 7.80705Z" />
        </svg>
    );
}
