import classNames from 'classnames';

export default function TwitterIcon({ className }: { className?: string }) {
    return (
        <svg
            className={classNames('icon', 'icon-twitter', className)}
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M15.6678 0H18.7184L12.0538 7.39222L19.8941 17.4514H13.7552L8.94696 11.3505L3.44525 17.4514H0.392847L7.52128 9.54455L0 0H6.29478L10.641 5.57638L15.6678 0ZM14.5971 15.6794H16.2875L5.37629 1.67891H3.56237L14.5971 15.6794Z" />
        </svg>
    );
}
