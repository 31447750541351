import { Role } from '@gn/core/type';

export function getEMSContentPath(content) {
    return `https://ems.gitnation.org/contents/${content.id}/edit`;
}

export function getPortalEditContentPath(content) {
    return `/contents/${content.slug}/edit`;
}

export function getEditButtonURL(currentUser, content): string | null {
    if (!currentUser || !content) return null;
    const isAuthor = content.users?.some((user) => user.id === currentUser?.id);
    const isAdmin = currentUser?.role === Role.CoreTeam;

    if (isAdmin) {
        return getEMSContentPath(content);
    } else if (isAuthor) {
        return getPortalEditContentPath(content);
    } else {
        return null;
    }
}
