import React from 'react';
import { ContentCategory } from '@gn/core/type';
import { ArticleItem } from './ArticleItem';
import { VideoItem } from './VideoItem';
import { ContentListContent } from './types';
import { ContentListSchema } from '../content-page/seo/ContentListSchema';

const FIRST_NOT_LAZY_LOADING_IMAGES = 3;

interface ContentListProps {
    contents: ContentListContent[];
    highlightFeatured?: boolean;
    visibleContent?: boolean;
    visibleContentWithButton?: boolean;
    hideVideoProgress?: boolean;
    disableListSchema?: boolean;
}

export function ContentList(props: ContentListProps) {
    return (
        <>
            {props.disableListSchema ? null : <ContentListSchema contents={props.contents} />}
            <ContentListComponent {...props} />
        </>
    );
}

const ContentListComponent = ({
    contents,
    highlightFeatured,
    visibleContent,
    visibleContentWithButton,
    hideVideoProgress,
}: ContentListProps) => {
    const contentsList = contents.map((content, index) => {
        return (
            <ContentListItem
                key={`${content.id}_${index}`}
                content={content}
                highlightFeatured={highlightFeatured}
                visibleContent={visibleContent}
                visibleContentWithButton={visibleContentWithButton}
                isImgLazyLoading={index >= FIRST_NOT_LAZY_LOADING_IMAGES}
                hideVideoProgress={hideVideoProgress}
            />
        );
    });

    return <div className="row row-margin">{contentsList}</div>;
};

export interface ContentListItemProps {
    content: ContentListContent;
    highlightFeatured?: boolean;
    horizontalLayout?: boolean;
    wrapperStyles?: React.CSSProperties;
    skipWrapper?: boolean;
    visibleContent?: boolean;
    visibleContentWithButton?: boolean;
    isImgLazyLoading?: boolean;
    hideVideoProgress?: boolean;
    openLinksInNewTab?: boolean;
    bookmarkVisible?: boolean;
}

export const ContentListItem = ({
    skipWrapper,
    content,
    highlightFeatured,
    visibleContent,
    visibleContentWithButton,
    isImgLazyLoading = true,
    horizontalLayout,
    hideVideoProgress,
    openLinksInNewTab,
    bookmarkVisible,
}: ContentListItemProps) => {
    if (content.category !== ContentCategory.Talk) {
        return (
            <ArticleItem
                isImgLazyLoading={isImgLazyLoading}
                skipWrapper={skipWrapper}
                content={content}
                highlightFeatured={highlightFeatured}
                visibleContent={visibleContent}
                visibleContentWithButton={visibleContentWithButton}
                horizontalLayout={horizontalLayout}
                hideVideoProgress={hideVideoProgress}
                openLinksInNewTab={openLinksInNewTab}
                bookmarkVisible={bookmarkVisible}
            />
        );
    } else {
        return (
            <VideoItem
                isImgLazyLoading={isImgLazyLoading}
                skipWrapper={skipWrapper}
                content={content}
                highlightFeatured={highlightFeatured}
                visibleContent={visibleContent}
                visibleContentWithButton={visibleContentWithButton}
                horizontalLayout={horizontalLayout}
                hideVideoProgress={hideVideoProgress}
                openLinksInNewTab={openLinksInNewTab}
                bookmarkVisible={bookmarkVisible}
            />
        );
    }
};
