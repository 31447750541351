import { EventRegistrationStatus } from '@gn/db/client';
import { EventRegistrationTicketType } from './type';

export const StatusOrder = [
    EventRegistrationStatus.Completed,
    EventRegistrationStatus.Registered,
    EventRegistrationStatus.Pending,
    EventRegistrationStatus.New,
    EventRegistrationStatus.Canceled,
];

export const TicketTypeOrder = [
    EventRegistrationTicketType.Ticket,
    EventRegistrationTicketType.Multipass,
    EventRegistrationTicketType.Speaker,
    EventRegistrationTicketType.Badge,
    EventRegistrationTicketType.FastRegister,
    EventRegistrationTicketType.Live,
];

export enum BadgeTicketType {
    Full = 'Badge full ticket',
    Free = 'Badge free ticket',
}

export const UPGRADE_BADGE_TICKET_REQUIREMENT = 3;
export const TOP_CONTENT_VIEWS_THRESHOLD = 1000;

export const FIND_IP_TOKEN = 'a703715e97004facbd2d1c8cd36ad7fc';
