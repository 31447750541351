export function normalizeSocialUrl(urlOrUsername: string | null) {
    if (!urlOrUsername) {
        return urlOrUsername;
    }

    if (urlOrUsername.includes('https://') || urlOrUsername.includes('http://')) {
        const parts = urlOrUsername.split('/').filter(Boolean);
        return parts[parts.length - 1].split('?')[0];
    }

    return urlOrUsername.trim().replace('@', '').split('?')[0];
}

export function getClientSideQuery(variable: string) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
}

export async function hash(value: string) {
    const inputBytes = new TextEncoder().encode(value);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', inputBytes);
    // @ts-ignore
    const base64 = btoa(String.fromCharCode.apply(null, new Uint8Array(hashBuffer)));

    return base64;
}

export const formatStringList = (elements: string[], dividerWord = 'and') => {
    if (!elements.length) {
        return '';
    }
    let result = '';
    const firstPart = elements.slice(0, -1);
    const firstPartString = firstPart.join(', ');
    if (firstPartString) {
        result += firstPartString;
        if (firstPart.length > 1) {
            result += ',';
        }
        result += ` ${dividerWord} `;
    }
    return result + elements.slice(-1);
};
