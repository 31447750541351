import getTags from './tags/queries/getTags';
import { tagsInMenu } from '../configs';
import { Ctx } from 'blitz';
import getEvents from './events/queries/getEvents';
import { PORTAL_ORIGIN, REDIRECT_ROUTES } from '@gn/core/config';

export function getStandardPagePromises(context: Ctx): Promise<any>[] {
    const tagsPromise = getTags(
        {
            where: {},
            take: tagsInMenu,
        },
        context,
    );
    const eventsPromise = getEvents(
        {
            where: {
                hideFromList: false,
                endDate: {
                    gt: new Date(),
                },
                brand: {
                    partnerEvent: {
                        not: true,
                    },
                },
            },
            skip: undefined,
            take: undefined,
            orderBy: [
                {
                    isPromoted: 'desc',
                },
                {
                    startDate: 'asc',
                },
            ],
        },
        context,
    );
    return [eventsPromise, tagsPromise];
}

export const stopVideoInsideContainer = (containerElement: HTMLElement): void => {
    const iframe_tag = containerElement.querySelector('iframe');
    const video_tag = containerElement.querySelector('video');
    if (iframe_tag) {
        let iframeSrc = iframe_tag.src;
        iframe_tag.src = iframeSrc;
    }
    if (video_tag) {
        video_tag.pause();
    }
};

export function addCapitalLetter(word) {
    return word[0].toUpperCase() + word.slice(1);
}

export const createCanonicalUrl = (slug: string): string => {
    return `${PORTAL_ORIGIN}/${slug.toLowerCase()}`;
};

export const redirectToRoot = (router) => {
    REDIRECT_ROUTES.forEach(async (route) => {
        if (router.pathname.startsWith(route)) {
            await router.push('/');
        }
    });
};

export const EventsTabTitle = {
    future: (date: Date = new Date()) => `Tech Conferences for Developers in ${date.getFullYear()}`,
    past: () => 'IT Conferences by GitNation Foundation',
    js: () => 'JavaScript Conferences to Attend',
    react: () => 'React Conferences to Attend',
} as const;
