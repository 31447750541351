import { User } from '@gn/db';
import { normalizeSocialUrl } from '../utils/client';

export function getUserNameWithFallback(
    { name, twitter, github, linkedin, email }: Partial<User>,
    isFirstNameOnly = false,
): string {
    switch (true) {
        case !!name:
            return isFirstNameOnly ? name!.split(' ')[0] : name!;
        case !!(twitter || github || linkedin):
            return normalizeSocialUrl((twitter || github || linkedin)!)!;
        case !!email:
            return email!.split('@')[0];
        default:
            return 'GitNation resident';
    }
}
